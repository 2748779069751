body {
  background-image: url("minecart_bg.jpeg");
  background-attachment: fixed;
}

.app-body {
  width: inherit;
}

.topnav {
  overflow: hidden;
  height: 160px;
  font-family: sans-serif;
}

.topnav2{
  display: none;
}

p.logo {
  display: inline;
  }
p.logo img {
  margin-top: 3%;
  height: 53px;
  margin-left: 8%;}

.headmargin {
  margin-right: 11%;
}

.headmargin2 {
  margin-right: -8.5%;
}
/* Style the links inside the navigation bar */
.topnav a {
  float: right;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  margin-top: 3%;
  text-decoration: none;
  font-size: 60%;
  font-weight: bold;
}

i {
  color: #B89554;
}

.cw {
  border:none;
  background-color: rgba(179, 119, 0, 0.7);
  color: white;
  height: 35px;
  width: 155%;
  position: relative;
  left: 99%;
  font-size: 90%;
  top: -5.5px;
  letter-spacing: 0.5px;
  padding-left: 2px;
}

.cw:hover {
  cursor: pointer;
  background-color: rgba(179, 119, 0, 0.85);
}

.topnav .line1 {
  float: right;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  margin-top: 0.36%;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;
}

.line1 hr {
  border-color: #c68c53;
  width: 150%;
  margin-right: -152%;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav .line1:hover {
  background-color: rgba(0,0,0,0);
  }


/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

.outer-box {
  height: 19.5em;
  width: 21.4em;
  background-color: #4dff88;
  border-radius: 13px;
  position: relative;
  top: -1.5em;
  left: 22.5%;
  display: inline-block;
  box-shadow: 1px 1px 10px grey;


}

.outer-box2 {
  margin-left: 3em;
  background-color: #ff4d88;
  box-shadow: 1px 1px 10px grey;
}

.App-box {
  background-color: #181818;
  border-bottom: 4.6px solid #00e673;
  border-radius: 10px; 
  height: 18.8em;
  width: 21em;
  margin-left: 0.93%;
  position: relative;
  top: 0.95%;
}

.box-color {
  border-bottom-color: #ff1a66;
}

.input1 input {
   background-color: #181818;
   color: white;
   border: 0.2px solid grey;
   height: 50px;
   width: 86%;
   margin-top: 12%;
   margin-left: 7%;
   font-size: 115%;
   border-radius: 10px;
   text-align: center;
   box-shadow: 2px 2px 2px grey;

}

.input2 input {
   background-color: #181818;
   color: white;
   border: 0.2px solid grey;
   height: 50px;
   width: 86%;
   margin-top: 7%;
   margin-left: 7%;
   font-size: 115%;
   border-radius: 10px;
   text-align: center;
   box-shadow: 2px 2px 2px grey;
}

.submt {
  width: 50%;
  height: 33px;
  margin-left: 24.3%;
  margin-top: 6%;
  border: none;
  border-radius: 13px;
  border-bottom: 2.74px solid #00e673;
  font-size: 65%;
  font-weight: bold;
  background-color: #4dff88;
  color: white;
}

.box-color .submt {
  background-color: rgb(255, 26, 102);
  border-bottom-color: #cc0044;
}

.submt:hover, .row_button:hover {
  cursor: pointer;
  background-color: #33ff77;
}

.box-color .submt:hover{
  background-color: #ff0055;
}

.header1 {
  font-size: 59%;
  font-weight: bold;
  position: relative;
  top: 6%;
  left: 7.5%;
  color: #B89554;
}

.header2 {
  font-size: 73%;
  position: relative;
  top: 10%;
  left: 7.5%;
  color: white;
}

.outer-box2 .header2 {
  left: 5%;
}

.header2 span {
  font-weight: bold;
  font-size: 85%;
}

.mid-text {
  font-size: 60.4%;
  font-weight: bold;
  position: relative;
  top: 4.8%;
  left: 7.5%;
  color: white;
}

.balance1 {
  float: right;
  margin-right: 14%;
}

.input1 input:focus, .input2 input:focus {
  outline: none;
}

 .input1 span {
  margin-left: -85px;
  font-size: 80%;
  font-weight: bold;
}

.input1 span span {
  position: relative;
  left: 20%;
  top: 14px;
  font-size: 58%;
  font-weight: bold;
  color: #ffcc00;
}

.input2 span {
  margin-left: -85px;
  font-size: 80%;
  font-weight: bold;
}

.x-bar {
  width: 55%;
  margin-left: 21.55%;
}

.header3 {
  color: #B89554;
  text-shadow: 1px 1px 1px #181818, 2px 2px 1px #181818;
  font-weight: bold;
  font-size: 95%;
  letter-spacing: 1px;
  margin-left: 22em;
  margin-top: 1.18%;
}

.tabs {
  text-shadow: 1px 1px 1px #181818;
  margin-left: 32.1em;
  font-size: 65%;
  margin-top: 0.1em;
  font-weight: 500;
  height: 30px;
  color: white;
}

.tabs span:first-child {
  margin-left: 1.3em;
}

.tabs span {
  height: inherit;
  width: 8.5em;
  display: inline-block;
  padding-top: 13px;
  padding-left: 11px;
}

.tabs span:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.3);
}

.active-tab {
  background-color: rgba(0,0,0,0.7);
}

.load_icon{
  display: none;
  font-size: 90%;
  margin-left: 350px;
  margin-top: 13px;
  color: white;
  text-shadow: 1px 1px 1px #181818;
}

#load_icon1 {
  display: none;
  color: white;
  margin-left: 350px;
  text-shadow: 1px 1px 1px #181818;
  font-size: 90%;
}

.goldx-item {
  width: 45em;
  height: 118px;
  margin-top: 1.4em;
  margin-bottom: 1em;
  margin-left: 23.5%;
  border-bottom: 5px solid #bfbfbf;
  border-radius:10px;
  box-shadow: 1px 1px 10px grey;
  background-color: #181818;
}

.row1 {
  color: #B89554;
  font-size: 64%;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  top: 1.48em;
  left:2.3em;
}

.row1_2 {
  font-size: 50%;
  margin-left: 40em;
}

.row2 {
  font-size: 70%;
  font-weight: bold;
  position: relative;
  top: 2.6em;
  left:2.3em;
  color: white;
}

.row2_2  {
  font-size: 99%;
  top: 1.84em;
  left: 1.6em;
}

.color_case {
  color: #B89554;

}

.color_case1 {
  color: #ffcc00;
}


.row2_3 {
  margin-left: 3.88em;
}

.row2_4 {
  font-size: 99%;
  top: 1.84em;
  left: 1.6em;
}

.note {
  font-size: 65%;
  margin-top: -0.2em;
  margin-left: 2em;
  color: white;
}

.bold_text {
  font-weight: bold;
}

.row_button {
  width: 22%;
  height: 33px;
  margin-left: 74.3%;
  margin-top: 1%;
  border: none;
  border-radius: 13px;
  border-bottom: 2.74px solid #00e673;
  font-size: 65%;
  font-weight: bold;
  background-color: #4dff88;
  color: white;
}

/* The alert message box */
.alert {
  width: 300px;
  padding: 11px;
  background-color: #f44336; /* Red */
  color: white;
}

#show-alert {
  margin-left: 0px;
  z-index: 3;
  position: fixed;
  transition: opacity 24s;
}

#show-alert1 {
  margin-left: 0px;
  z-index: 3;
  position: fixed;
  transition: opacity 24s;
}

#show-alert2 {
  margin-left: 0px;
  z-index: 3;
  position: fixed;
  transition: opacity 24s;
}

.tabcontent {
  margin-bottom: 150px;
}

.app-footer {
  background-color: black;
  color: #B89554;
  width: 100%;
  position: fixed;
  bottom: 0%;
}

.app-footer div:first-child {
  font-size: 60%;
  margin-top: 10px;
  margin-left: 30px;
  letter-spacing: 2px;
}

.app-footer div:nth-child(2) {
  font-size: 45%;
  margin-top: 2px;
  margin-left: 30px;
  letter-spacing: 2px;
}

.app-footer div:nth-child(3) {
  font-size: 47%;
  margin-top: 2px;
  margin-left: 30px;
  color: grey;
  text-decoration: underline;
}

.app-footer div span {
  float: right;
  margin-right: 40px;
}

.app-footer div span img {
  height: 30px;
}

.app-footer .icon-list {
  text-align: center;
}

.app-footer .icon-list i {
  margin-left: 2%;

}

.app-footer .icon-list .rights {
  font-size: 52%;
  color: grey;
}


@media only screen and (max-width: 1024px) {

.outer-box {
  left: 15.5%;
}
.x-bar {
  width: 45.8em;
  margin-left: 14.55%;
}

.header3 {
  margin-left: 19%;
}

.tabs {
  margin-left: 19%;
}

.goldx-item  {
  margin-left: 16.8%;  
}


}

@media only screen and (max-width: 928px) {

.topnav a {
  font-size: 47%;
  padding-left: 10px;
}

.cw {
  font-size: 62%;
  padding-left: 0px
}

.outer-box {
  left: 12.5%;
}
.x-bar {
  margin-left: 12.05%;
}

.header3 {
  margin-left: 16%;
}

.tabs {
  margin-left: 16%;
}

.goldx-item  {
  margin-left: 13.8%;  
}


}

@media only screen and (max-width: 880px) {

.outer-box {
  left: 9.5%;
}

.x-bar {
  margin-left: 9.05%;
}

.header3 {
  margin-left: 14%;
}

.tabs {
  margin-left: 14%;
}

.goldx-item  {
  margin-left: 11.8%;  
}


}

@media only screen and (max-width: 836px) {

.outer-box {
  left: 2em;
}

.x-bar {
  margin-left: 6.05%;
}

.header3 {
  margin-left: 11%;
}

.tabs {
  margin-left: 11%;
}

.goldx-item  {
  margin-left: 8.8%;  
}


}

@media only screen and (max-width: 780px) {
  .outer-box {
  left: 2%;
}

}

@media only screen and (max-width: 768px) {

.outer-box {
  left: 2.6%;
  height: 20.5em;
}

.App-box {
  height: 19.8em
}

.outer-box2 {
}

.input1 input {
   border: 0.4px solid grey;
   height: 60px;
   width: 86%;
   margin-top: 14%;
   margin-left: 7%;
   font-size: 125%;
   border-radius: 10px;
   text-align: center;
   box-shadow: 2px 2px 2px grey;

}

.input2 input {
   border: 0.4px solid grey;
   height: 60px;
   width: 86%;
   margin-top: 9%;
   margin-left: 7%;
   font-size: 125%;
   border-radius: 10px;
   text-align: center;
   box-shadow: 2px 2px 2px grey;
}

.submt {
  width: 50%;
  height: 43px;
  margin-left: 24.3%;
  margin-top: 6.5%;
  border: none;
  border-radius: 13px;
  border-bottom: 2.74px solid #00e673;
  font-size: 85%;
  font-weight: bold;
  background-color: #4dff88;
  color: white;
}

.x-bar {
  margin-left: 3.05%;
}

.header3 {
  margin-left: 8%;
}

.tabs {
  margin-left: 8%;
}

.goldx-item  {
  margin-left: 5.8%;  
}


}

@media only screen and (max-width: 756px) {
.outer-box {
  left: 1.5%;
}

.outer-box2 {
  left: 0%;
}


}


@media only screen and (max-width: 740px) {

.topnav {
  display: none;
}

.cw {
  font-size: 80%;
}

.menu {
  height: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: height 0.5s;
}

.menu a {
  display: block;
  width: inherit;
}

.showMenu {
  display: block;
}

.topnav2 .line1 {
  display: none;
}

.topnav2 {
  width: inherit;
  display: block;
  margin-top: 0%;
  margin-left: 0%;
  margin-bottom: 2%;
  text-align: center;

}

.menu a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu a:last-child {
  border-bottom: 5px solid black;
}

.topnav2 div a.line1 {
  display: block;

}

.topnav2 div a.line1 .cw {
  left: 0%;
  top: 0%;
  height: 40px;
  width: 90%;
}

.topnav2 .line1 hr {
  margin-right: 0%;
  width: 90%;
  position: relative;
  left: -4.8%;
}

.topnav2 div a {
  text-decoration: none;
  color: black;
}

.topnav2 .menu a {
  width: 100%;
  color: white;
  text-shadow: 1px 1px 1px #181818;
  text-align: center;
  font-size: 75%;
  font-weight: 500;
}

.topnav2 div:first-child {
  text-align: left;
} 

.topnav2 p.logo img {
  height: 40px;
  margin-top: 2%;
}

.topnav2 div a i{
  text-decoration: none;
  font-size: 230%;
  float: right;
  margin-right: 5%;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

.topnav2 div a.line1 .cw {
  font-size: 80%;
}

.App-box {
  height: 21.8em;
  width: 24em;
}

.outer-box {
  top: 1.3em;
  height: 22.5em;
  width: 24.4em;
  display: block;
  margin-left: 0%;
  left: 23%;
  box-shadow: 1px 1px 20px grey;
}

.outer-box2 {
  position: relative;
  top: 4em;
}

.x-bar {
  margin-left: 3.05%;
  margin-top: 6.5em;
  width: 90%;
}

.header3 {
  width: inherit;
  margin-left: 15.3px;
  font-size: 105%;
}

.header1 {
  font-size: 69%;
}

.header2 {
  font-size: 83%;
}

.outer-box2 .header2 {
  left: 4%;
}

.header2 span {
  font-size: 95%;
}

.mid-text {
  font-size: 70.4%;
}

.tabs {
  margin-left: 5px;
  font-size: 75%;
}

.load_icon {
  margin-left: 118px;
}

#load_icon1 {
  margin-left: 118px;
}

.goldx-item  {
  margin-left: 8px;
  width: 45em;
  height: 118px;
  box-shadow: 1px 1px 15px grey;
}

.row1 {
  color: rgba(179, 119, 0, 0.9);
  font-size: 64%;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  top: 1.48em;
  left:2.3em;
}

.row1_2 {
  font-size: 50%;
  margin-left: 40em;
}

.row2 {
  font-size: 70%;
  font-weight: bold;
  position: relative;
  top: 2.6em;
  left:2.3em;
}

.row2_2  {
  font-size: 99%;
  top: 1.84em;
  left: 1.6em;
}


}

@media only screen and (max-width: 684px) {
  .outer-box {
  left: 20%;
}

.goldx-item {
  scale: 0.9;
  margin-left: -18px;
}

}

@media only screen and (max-width: 664px) {

.tabs {
  font-size: 65%;
}

.goldx-item {
  scale: 0.85;
  margin-left: -30px;
}

}

@media only screen and (max-width: 624px) {
  .outer-box {
  left: 17.5%;
}

.goldx-item {
  scale: 0.83;
  margin-left: -50px;
}

}

@media only screen and (max-width: 570px) {
  .outer-box {
  left: 15%;
}

.goldx-item {
  scale: 0.75;
  margin-left: -78px;
}

}

@media only screen and (max-width: 540px) {
  .outer-box {
  left: 13.3%;
}

.goldx-item {
  scale: 0.6;
  margin-left: -118px;
}

}

@media only screen and (max-width: 516px) {
  .outer-box {
  left: 12.3%;
}

}

@media only screen and (max-width: 500px) {
  .outer-box {
  left: 11%;
}

}

@media only screen and (max-width: 480px) {
  .outer-box {
  left: 9.3%;
}

}

@media only screen and (max-width: 465px) {
  .outer-box {
  left: 7.3%;
}

.goldx-item {
  scale: 0.6;
  margin-left: -128px;
}

}

@media only screen and (max-width: 446px) {
  .outer-box {
  left: 6.3%;
}

.goldx-item {
  scale: 0.58;
  margin-left: -138px;
}

}

@media only screen and (max-width: 436px) {
  .outer-box {
  left: 5.0%;
}

.goldx-item {
  scale: 0.55;
  margin-left: -148px;
}

}


@media only screen and (max-width: 425px) {

  .topnav2 div a i{
    font-size: 200%;
    margin-top: 2.6%;
  }

  .tabs {
  width: inherit;
  font-size: 45%;
  font-weight: bold;
}

.header3 {
  font-size: 70%;
}

.tabs span:last-child {
  width: 80px;
}

.outer-box {
  scale:0.8;
  left: 4%;
}

.outer-box2 {
  position: relative;
  top: 0%;
  margin-top: -10%;
}

.x-bar {
  margin-top: -10px;
}

.goldx-item {
  scale: 1;
  width: 95%;
  height: 75px;
  margin-top: 1.4em;
  margin-bottom: 1em;
  margin-left: 15px;
  border-bottom: 5px solid #bfbfbf;
  border-radius:10px;
  box-shadow: 1px 1px 10px grey;
}

.row1 {
  color: rgba(179, 119, 0, 0.9);
  font-size: 50%;
  width: inherit;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  top: -1px;
  left: 10.3px;
}

.row1_2 {
  font-size: 50%;
  margin-left: 85px;
}

.row2 {
  font-size: 50%;
  font-weight: bold;
  position: relative;
  top: 0px;
  left:10.3px;
}

.row2_2  {
  font-size: 73%;
  top: 0px;
  left: 11.6px;
}

.row2_3 {
  margin-left: 12.88px;
}

.row2_4 {
  font-size: 73%;
  top: 0px;
  left: 11.6px;
}

.row_button {
  width: 80px;
  height: 23px;
  margin-left: 76.3%;
  margin-top: 0%;
  position: relative;
  top: -10px;
  border: none;
  border-radius: 8px;
  border-bottom: 2.74px solid #00e673;
  font-size: 50%;
  font-weight: bold;
  background-color: #4dff88;
  color: white;
}

.note {
  font-size: 37%;
  margin-top: -20.2px;
  margin-left: 10.3px;
}

}

@media only screen and (max-width: 400px) {

.header3 {
  font-size: 70%;
}

.tabs {
  font-size: 45%;
}

.outer-box {
  scale:0.65;
  left: 1%;
  top: -20px;
}

.outer-box2 {
  top: -100px;
}

.x-bar {
  margin-top: -145px;
}

.goldx-item {
  margin-left: 8px;
}

.row1_2 {
  font-size: 50%;
  margin-left: 55px;
}

.row_button {
  top: -23px;
  margin-left: 73.3%;
}

}

@media only screen and (max-width: 385px) {
  .outer-box {
  left: -0.7%;
}

}

@media only screen and (max-width: 380px) {
  .outer-box {
  left: -1.3%;
}

.row1 {
  font-size: 48%;
}

.row1_2 {
  font-size: 48%;
  margin-left: 35px;
}

.topnav2 .menu a {
  font-size: 55%;
}

.topnav2 div a.line1 .cw {
  font-size: 100%;
}

}

@media only screen and (max-width: 369px) {
  .outer-box {
  left: -2.4%;
}

.row1_2 {
  font-size: 48%;
  margin-left: 45px;
}

}

@media only screen and (max-width: 360px) {

.header3 {
  font-size: 70%;
}

.tabs span:last-child {
  width: 70px;
}

.outer-box {
  left: -3.9%;
}

.row1_2 {
  font-size: 45%;
  margin-left: 30px;
}


}

@media only screen and (max-width: 342px) {

.topnav2 div a i{
    font-size: 180%;
    margin-top: 3%;
  }

.header3 {
  font-size: 70%;
}

.tabs span:last-child {
  width: 65px;
}

.outer-box {
  left: -6.75%;
}

}

@media only screen and (max-width: 339px) {
  .tabs {
  font-size: 40%;
}

.outer-box {
  left: -7%;
}

}

@media only screen and (max-width: 330px) {
 .row1_2 {
  margin-left: 15px;
}

.row1 {
  font-size: 43%;
}

.row2_2 {
  font-size: 58%;
}

.row2_4 {
  font-size: 58%;
}

.row_button {
  margin-left: 70%;
}

}

@media only screen and (max-width: 323px) {

.header3 {
  font-size: 65%;
}

.tabs {
}

.outer-box {
  left: -10.5%;
}


}

@media only screen and (max-width: 317px) {
  .tabs {
    font-size: 35%;
  }
}

@media only screen and (max-width: 308px) {

.header3 {
  font-size: 65%;
}

.tabs span:last-child {
  width: 60px;
}

.outer-box {
  left: -13.5%;
}

.goldx-item {
  height: 85px;
}

.row1 {
  font-size: 35%;
}

.row1_2 {
  font-size: 35%;
  margin-left: 15px;
}

.row2_3 {
  margin-left: 10px;
}

.row_button {
  font-size: 45%;
  width: 60px;

}

}

@media only screen and (max-width: 302px) {

  .outer-box {
    left: -15%;
  }
}

@media only screen and (max-width: 295px) {

  .outer-box {
    left: -17.8%;
  }

  .tabs {
    font-size: 30%;
  }
}

@media only screen and (max-width: 270px) {

  .tabs {
    font-size: 24%;
  }

  .goldx-item {

  }

  .row1 {
    font-size: 15%;
  }

  .row1_2 {
    font-size: 15%;
    margin-left: 5px;
  }

  .row2_3 {
  margin-left: 3px;
  }

  .row_button {
    margin-left: 72.5%;
  }
}